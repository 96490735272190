<template>
  <div class="bbr-program-plans--details">
    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="7">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Plan Details</h2>

          <v-btn
            class="primary--text mr-5"
            @click="saveForLater"
            :loading="form.$busy"
            depressed
            text
          >
            Save & Finish Later
          </v-btn>

          <v-btn
            v-if="!hasPrograms"
            class="bg-primary-gradient primary next-button px-12"
            @click="saveAndComplete"
            :loading="form.$busy"
            depressed
            text
          >
            <span v-if="!isCompleted"> Complete Plan </span>
            <span v-else> Save Plan </span>
            <v-icon> {{ icons.check }} </v-icon>
          </v-btn>

          <v-btn
            v-else
            class="bg-primary-gradient primary next-button px-12"
            @click="saveAndCompleteNext"
            :loading="form.$busy"
            depressed
            text
          >
            <span> Next </span>
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="7">
        <plan-details-form :form-data="form" />
      </v-col>
    </v-row>
    <confirm-dialog ref="confirmDialog" />
  </div>
</template>

<script>
import PlanDetailsForm from '../components/PlanDetailsForm'
import Form from '@/utils/form'

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { mdiCheck, mdiArrowRight } from '@mdi/js'
import { pick } from 'lodash'
import moment from 'moment'
import ConfirmDialog from '@/components/modals/ConfirmDialog'

export default {
  name: 'PlanDetails',

  components: {
    PlanDetailsForm,
    ConfirmDialog,
  },

  data() {
    return {
      form: new Form({
        id: this.$attrs.id,
        name: null,
        features: [],
        icons: [],
        week_count: null,
        description: null,
        is_featured: false,
        has_free_trial: false,
        primary_color: { r: 197, g: 215, b: 177, a: 1 },
        image_public_id: null,
        secondary_image_public_id: null,
        completed: false,
        active_from: null,
        active_to: null,
        rollout_at: null,
        sunset_at: null,
      }),
      icons: {
        check: mdiCheck,
        next: mdiArrowRight,
      },
    }
  },

  computed: {
    ...mapState({
      selectedPlan: (state) => state.plans.selectedPlan,
    }),

    ...mapGetters({
      planPrograms: 'plans/getSelectedPlanPrograms',
    }),

    hasPrograms() {
      return !!this.planPrograms.length
    },

    isCompleted() {
      return !!this.selectedPlan.completed_at
    },
  },

  created() {
    if (this.$attrs.id) {
      this.fillForm(this.selectedPlan)
    }
  },

  methods: {
    ...mapActions({
      savePlan: 'plans/savePlan',
    }),

    ...mapMutations({
      updatePlan: 'plans/updatePlan',
      setSelectedPlan: 'plans/setSelectedPlan',
      updatePlanOnDraftList: 'plans/updatePlanOnDraftList',
      updatePlanOnCompletedList: 'plans/updatePlanOnCompletedList',
    }),

    async saveForLater() {
      this.form.completed = false

      this.form.$busy = true
      this.form.$clearErrors()

      await this.savePlan(this.form)
        .then((data) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            this.updatePlanOnDraftList(data)

            this.$router.push({ name: 'drafts.plans' })
          })
        })
        .catch(({ response }) => {
          this.form.$timeout(() => {
            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }
            this.form.$busy = false
          })
        })
    },

    async rolloutChecksPassed() {
      const isRolloutBeforeSaleDate =
        this.form.rollout_at &&
        this.form.active_from &&
        this.form.rollout_at < this.form.active_from

      const confirmRolloutBeforeSale =
        isRolloutBeforeSaleDate &&
        (await this.$refs.confirmDialog.open(
          'Rolling Out Before Sale Date',
          'Roll out date and time happens before Sale Date. Are you sure?'
        ))

      if (isRolloutBeforeSaleDate && !confirmRolloutBeforeSale) return false

      const isRolloutWithoutSaleDate =
        this.form.rollout_at && !this.form.active_from

      const confirmRolloutWithoutSaleDate =
        isRolloutWithoutSaleDate &&
        (await this.$refs.confirmDialog.open(
          'Rolling Out Without Sale Date',
          'Rolling out without Sale Date. Are you sure?'
        ))

      if (isRolloutWithoutSaleDate && !confirmRolloutWithoutSaleDate)
        return false

      const isImmediateRollout =
        this.form.rollout_at && moment().isAfter(this.form.rollout_at)

      const confirmImmediateRollout =
        isImmediateRollout &&
        (await this.$refs.confirmDialog.open(
          'Rolling Out Immediately',
          'This plan will roll out to all applicable members immediately. Are you sure?'
        ))

      if (isImmediateRollout && !confirmImmediateRollout) return false

      this.form.immediate_rollout =
        !!isImmediateRollout && !!confirmImmediateRollout

      return true
    },

    async saveAndComplete() {
      if (!(await this.rolloutChecksPassed())) return
      this.form.completed = true
      this.form.$busy = true
      this.form.$clearErrors()

      await this.savePlan(this.form)
        .then((data) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            this.updatePlanOnCompletedList(data)

            this.$router.push({ name: 'active.plans' })
          })
        })
        .catch(({ response }) => {
          this.form.$timeout(() => {
            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }
            this.form.$busy = false
          })
        })
    },

    async saveAndCompleteNext() {
      if (!(await this.rolloutChecksPassed())) return
      this.form.completed = true
      this.form.$busy = true
      this.form.$clearErrors()

      await this.savePlan(this.form)
        .then((data) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            this.updatePlanOnCompletedList(data)
            this.setSelectedPlan(data)

            this.$router.push({
              name: 'plan.programs',
              params: { id: data.id },
            })
          })
        })
        .catch(({ response }) => {
          this.form.$timeout(() => {
            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }
            this.form.$busy = false
          })
        })
    },

    fillForm(plan) {
      let data = pick(plan, [
        'id',
        'name',
        'features',
        'week_count',
        'description',
        'is_featured',
        'icons',
        'primary_color',
        'image_public_id',
        'secondary_image_public_id',
        'has_free_trial',
      ])
      data['active_from'] = plan.active_from
        ? moment(plan.active_from).format('YYYY-MM-DD hh:mm:ss')
        : null

      data['active_to'] = plan.active_to
        ? moment(plan.active_to).format('YYYY-MM-DD hh:mm:ss')
        : null

      data['rollout_at'] = plan.rollout_at
        ? moment(plan.rollout_at).format('YYYY-MM-DD hh:mm:ss')
        : null

      data['sunset_at'] = plan.sunset_at
        ? moment(plan.sunset_at).format('YYYY-MM-DD')
        : null

      this.form = new Form(data)
    },
  },

  watch: {
    selectedPlan: {
      deep: true,
      handler() {
        if (this.$attrs.id) {
          this.fillForm(this.selectedPlan)
        }
      },
    },
  },
}
</script>
